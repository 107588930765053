<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>仓库名称：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="warehouse_id" filterable clearable :disabled="id.length > 0">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-if="id">
          <span>客户名称：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight60" :value="detailsInfo.customer_name" disabled></i-input>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品" :loading="loading"> </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !loading">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
      <div class="summary" v-if="totalMoney > 0 && allNum > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totalMoney }}</span>
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" disabled v-model="postFrom.remark"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1000" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="changeSupplierStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换客户名称？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangeSupplier">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangeSupplier">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'salesReturnDetailConsign',
  components: {
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_amount * 1
      })
      return totle
    },
    totalMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + (e.product_total_price ? e.product_total_price * 1 : 0)
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      loading: true,
      storeList: [],
      warehouse_id: '',
      changeSupplierStatus: false,
      id: '',
      supplierIndex: -1,
      saveIndex: '', // 保存上一次的index
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        warehouse_id: '',
        product_id: '',
        product_code_array: [],
      },
      postFrom: {
        customer_info: {},
        product_info: [],
        remark: '',
      },
      productList: [],
      modelList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'product_model_name',
          align: 'center',
          width: 180,
        },
        {
          title: '货号/SKU',
          key: 'product_item_number',
          align: 'center',
          width: 180,
        },
        {
          title: '销售单价',
          key: 'product_unit_price',
          align: 'center',
          width: 180,
        },
        // {
        //   title: '税率',
        //   key: 'tax_rate',
        //   align: 'center',
        //   width: 140,
        // },
        {
          title: '不含税价',
          key: 'product_after_tax_unit_price',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', (param.row.product_unit_price / (1 + param.row.tax_rate / 100)).toFixed(4))])
          },
        },
        {
          title: '数量',
          key: 'product_amount',
          align: 'center',
          width: 180,
        },
        {
          title: '单位',
          key: 'product_unit',
          align: 'center',
          width: 180,
        },
        {
          title: '有效天数',
          key: 'valid_days',
          align: 'center',
          width: 140,
        },
        {
          title: '小计',
          align: 'center',
          width: 180,
          render: (h, param) => {
            return h('div', [h('span', param.row.product_total_price ? '¥' + param.row.product_total_price : '')])
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                  },
                  on: {
                    click: () => {},
                  },
                },
                '-'
              ),
            ])
          },
        },
      ],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'product_model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'product_item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '销售单价',
          key: 'product_unit_price',
          align: 'center',
          width: 180,
        },
        {
          title: '单位',
          key: 'product_unit',
          align: 'center',
          width: 95,
        },
      ],
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
      detailsInfo: {},
    }
  },
  mounted() {
    this.queryStoreList()
    this.id = this.$route.query.id || ''
    if (this.id) {
      this.queryDetails(this.id)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = false
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 查询详情
    queryDetails(id) {
      this.loading = true
      this.$http
        .get(this.$apiConsign.saleReturnDetail, { return_code: id }, true)
        .then(res => {
          this.loading = false
          this.detailsInfo = res.data
          this.meterList = res.data.return_detail
          this.postFrom.remark = res.data.remark
          this.warehouse_id = this.queryFrom.warehouse_id = res.data.warehouse_id
          for (let i = 0; i < this.clientNameList.length; i++) {
            if (this.clientNameList[i].customer_id == res.data.customer_id && this.clientNameList[i].customer_name == res.data.customer_name) {
              this.supplierIndex = i
            }
          }
          for (let i = 0; i < this.meterList.length; i++) {
            this.$set(this.meterList[i], 'product_total_price', this.meterList[i].product_unit_price * this.meterList[i].product_amount)
          }
        })
        .then(() => {
          this.queryProduct('', this.detailsInfo.customer_id, this.detailsInfo.customer_type)
        })
    },
    // 客户名称改变
    clienChange(e) {
      // if (e >= 0) {
      //   this.queryFrom.product_id = ''
      //   this.queryFrom.product_code_array = []
      //   this.queryProduct('', this.clientNameList[e].customer_id, this.clientNameList[e].customer_type)
      // } else {
      //   this.queryFrom.product_id = ''
      //   this.queryFrom.product_code_array = []
      // }
      //  先判断有没有已选产品列表
      if (this.meterList.length > 0) {
        this.changeSupplierStatus = true
      } else {
        if (e >= 0) {
          this.queryFrom.product_id = ''
          this.queryFrom.product_code_array = []
          this.saveIndex = e
          this.queryProduct('', this.clientNameList[e].customer_id, this.clientNameList[e].customer_type)
        } else {
          this.queryFrom.product_id = ''
          this.queryFrom.product_code_array = []
        }
      }
    },
    // 在已有产品的情况下 确认更换供应商
    sureChangeSupplier() {
      this.changeSupplierStatus = false
      this.queryFrom.product_id = ''
      this.queryFrom.product_code_array = []
      this.productList = []
      this.meterList = []
      this.queryProduct('', this.clientNameList[this.supplierIndex].customer_id, this.clientNameList[this.supplierIndex].customer_type)
    },
    // 在已有产品的情况下 取消更改供应商
    cancleChangeSupplier() {
      this.supplierIndex = this.saveIndex
      this.changeSupplierStatus = false
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
      console.log(this.meterList, '删除')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        let select = this.meterList.map(item => item.product_id + item.code_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.code_id)
        })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (this.supplierIndex < 0 || !this.queryFrom.product_id) {
        this.$Message.warning('请先选择客户名称/产品名称后再查询')
        return
      }
      let query = {
        product_id: this.queryFrom.product_id,
        product_code_array: this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : '',
        customer_id: this.clientNameList[this.supplierIndex].customer_id,
        customer_type: this.clientNameList[this.supplierIndex].customer_type,
      }
      this.$http.get(this.$apiConsign.saleReturnProductSearch, query, false).then(res => {
        if (res.data.length > 0) {
          this.hadSelectList = []
          this.chooseList = res.data
          this.chooseStatus = true
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 查询产品
    queryProduct(id = null, customer_id, customer_type) {
      this.$http.get(this.$apiConsign.saleReturnProductShow, { product_id: id, customer_id: customer_id, customer_type: customer_type }, false).then(res => {
        if (!id) {
          this.productList = res.data
        } else {
          this.modelList = res.data
        }
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      if (!e) return
      this.modelList = []
      this.queryProduct(e, this.clientNameList[this.supplierIndex].customer_id, this.clientNameList[this.supplierIndex].customer_type)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
        return null
      }
      let str = e.target.value.trim().substring(0, 8)
      this.$set(this.meterList[index], name, str * 1)
      let total = this.meterList[index].product_amount * this.meterList[index].product_unit_price
      this.$set(this.meterList[index], 'product_total_price', total > 0 ? total.toFixed(2) : 0)
    },
    // 保存
    save(num) {
      if (!this.supplierIndex < 0) {
        this.$Message.warning('请选择客户名称')
        return
      }
      if (!this.warehouse_id) {
        this.$Message.warning('请选择仓库名称')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        if (!this.meterList[i].product_amount) {
          this.$Message.warning(`请完善第${i + 1}组产品的数量`)
          return
        }
      }
      this.postFrom.product_info = JSON.parse(JSON.stringify(this.meterList))
      this.postFrom.warehouse_id = this.warehouse_id
      if (num) {
        this.postFrom.is_submit = '1'

        this.postStatus = true
        return
      }
      this.postFrom.is_submit = '0'
      if (this.id) {
        this.postFrom.return_code = this.id
        this.postFrom.customer_info = {
          customer_id: this.detailsInfo.customer_id,
          customer_type: this.detailsInfo.customer_type,
        }
        this.$http.put(this.$apiConsign.saleReturn, this.postFrom, true).then(res => {
          this.$router.go(-1)
        })
      } else {
        this.postFrom.customer_info = {
          customer_id: this.clientNameList[this.supplierIndex].customer_id,
          customer_type: this.clientNameList[this.supplierIndex].customer_type,
        }
        this.$http.post(this.$apiConsign.saleReturn, this.postFrom, true).then(res => {
          this.$Message.success('保存成功')
          this.$router.go(-1)
        })
      }
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      if (this.id) {
        this.postFrom.customer_info = {
          customer_id: this.detailsInfo.customer_id,
          customer_type: this.detailsInfo.customer_type,
        }
        this.postFrom.return_code = this.id
        this.$http.put(this.$apiConsign.saleReturn, this.postFrom, true).then(res => {
          this.$router.go(-1)
        })
      } else {
        this.postFrom.customer_info = {
          customer_id: this.clientNameList[this.supplierIndex].customer_id,
          customer_type: this.clientNameList[this.supplierIndex].customer_type,
        }
        this.$http.post(this.$apiConsign.saleReturn, this.postFrom, true).then(res => {
          this.$Message.success('提交成功')
          this.$router.go(-1)
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
